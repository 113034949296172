import React, { useRef } from 'react'
import serialize from 'form-serialize'
import { navigate } from '@reach/router'

const NetlifyForm = React.forwardRef(
  ({ name, action, children, ...props }, ref) => {
    const formRef = useRef(ref)
    const handleSubmit = async e => {
      console.log('NetlifyForm handleSubmit', e)
      e.preventDefault()
      console.log('e', formRef.current)
      const serializedForm = serialize(formRef.current)
      await fetch('/', {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: serializedForm,
      })
      const formValesObj = serialize(formRef.current, { hash: true })
      return navigate(action, { state: formValesObj })
    }
    return (
      <form
        name={name}
        method={'post'}
        action={action}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        ref={formRef}
        onSubmit={handleSubmit}
        {...props}
      >
        <input type="hidden" name="form-name" value={name} />
        <p css={{ display: 'none' }}>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        {children}
      </form>
    )
  }
)

export default NetlifyForm
